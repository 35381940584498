<template>
  <vca-card>
    <h2>{{ $t("email.list.header") }}</h2>
    <vca-card class="shadowed">
      <MailboxSelection />
    </vca-card>
    <vca-card class="shadowed vca-center">
      <EmailDraftTable />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('email.preview.title', { 0: current.subject })"
        @close="current = null"
      >
        <EmailPreview
          v-model="current"
          :preview="false"
          :deleteable="true"
          @delete="remove"
          @send="send"
        />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import EmailPreview from "@/components/emails/EmailPreview";
import EmailDraftTable from "@/components/emails/EmailDraftTable";
import MailboxSelection from "../../components/emails/MailboxSelection.vue";
export default {
  name: "EmailDraftList",
  components: { EmailDraftTable, EmailPreview, MailboxSelection },
  methods: {
    remove() {
      this.$store.dispatch("emails/mailbox/message/delete").then(() => {
        this.$store.dispatch("emails/mailbox/get");
      });
    },
    send() {
      this.$store.dispatch("emails/mailbox/message/send").then(() => {
        this.$store.dispatch("emails/mailbox/get").then(() => {
          this.$router.push({ path: "/emails/outbox" });
        });
      });
    },
  },
  created() {
    this.current = null;
  },
  computed: {
    current: {
      get() {
        return this.$store.state.emails.mailbox.message.current;
      },
      set(value) {
        this.$store.commit("emails/mailbox/message/current", value);
      },
    },
    ...mapGetters({
      user: "user/current",
    }),
  },
};
</script>
